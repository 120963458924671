import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Container,
  Paper,
  useTheme,
  Grid,
  TextFieldProps,
} from "@material-ui/core";
import {
  useForm,
  useFormState,
  useController,
  UseFormGetValues,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { rhythm, AllowInputNumber, AllowInputDecimal } from "src/utils";

const schema = yup.object({
  vehiclePrice: yup.string(), // ราคารถ
  downPercent: yup.string().max(101, "กรุุณากรอกไม่เกิน 100 %"), // เงินดาวน์ (เปอร์เซ็น)
  down: yup.string(), // เงินดาวน์บาท
  financeAmount: yup.string(), // ยอดจัดไฟแนนซ์
  installmentTotalMonth: yup.string(), // จำนวนเดือนที่ผ่อน
  interestPercent: yup.string().max(101, "กรุุณากรอกไม่เกิน 100 %"), // ดอกเบี้ย %
  totalInterest: yup.string(), // ดอกเบี้ยทั้งหมด
  totalPaidAmount: yup.string(), // ยอดรวมที่ต้องจ่าย
  monthlyInstallment: yup.string(), // ค่างวดต่อเดือน
});

interface FinanceVehicleCalculateProps {
  vehiclePrice: number;
  downPercent: number;
  down: number;
  financeAmount: number;
  installmentTotalMonth: number;
  interestPercent: number;
  totalInterest: number;
  totalPaidAmount: number;
  monthlyInstallment: number;
}

function FinanceVehicleCalculate() {
  const theme = useTheme();

  const [inputNameKeyStroke, setInputNameKeyStroke] = useState<
    keyof FinanceVehicleCalculateProps | string
  >();
  const { register, reset, watch, control, getValues, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      vehiclePrice: "0",
      downPercent: "0",
      down: "0",
      financeAmount: "0",
      installmentTotalMonth: "12",
      interestPercent: "0",
      totalInterest: "0",
      totalPaidAmount: "0",
      monthlyInstallment: "0",
    },
  });

  const { errors } = useFormState({ control });

  function InstallmentTotalMonthTextField(
    props: TextFieldProps & { control: any }
  ) {
    const { control, ...TextFieldMuiProps } = props;
    const {
      field: { ref, ...inputProps },
    } = useController({
      control: props.control,
      name: "installmentTotalMonth",
      defaultValue: 0,
    });

    return (
      <div style={{ width: "100%" }}>
        <TextField
          inputRef={ref}
          {...inputProps}
          onChange={(e) => {
            setInputNameKeyStroke("installmentTotalMonth");
            inputProps.onChange(e);
          }}
          {...TextFieldMuiProps}
        >
          {props.children}
        </TextField>
      </div>
    );
  }

  function getData(
    getValues: UseFormGetValues<any>
  ): FinanceVehicleCalculateProps {
    const interestPercentArray = [...getValues("interestPercent").toString()];
    if (interestPercentArray[interestPercentArray.length - 1] == ".") {
    }
    return {
      vehiclePrice: Number(
        getValues("vehiclePrice").toString().replace(/\,/g, "")
      ),
      downPercent: Number(
        getValues("downPercent").toString().replace(/\,/g, "")
      ),
      down: Number(getValues("down").toString().replace(/\,/g, "")),
      financeAmount: Number(
        getValues("financeAmount").toString().replace(/\,/g, "")
      ),
      installmentTotalMonth: Number(
        getValues("installmentTotalMonth").toString().replace(/\,/g, "")
      ),
      interestPercent: Number(getValues("interestPercent").toString()),
      totalInterest: getValues("totalInterest"),
      totalPaidAmount: Number(
        getValues("totalPaidAmount").toString().replace(/\,/g, "")
      ),
      monthlyInstallment: Number(
        getValues("monthlyInstallment").toString().replace(/\,/g, "")
      ),
    };
  }

  const monthToYear = (month: number) => Math.ceil(month / 12);

  //* เริ่มต้น
  useEffect(() => {
    const vehiclePrice = 1000000;
    const downPercent = 25;
    const installmentTotalMonth = 60;
    const interestPercent = 0;

    const down = (vehiclePrice * downPercent) / 100;
    const financeAmount = vehiclePrice - down;
    const interestPerYear = (financeAmount * interestPercent) / 100;
    const totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    const totalPaidAmount = +financeAmount + totalInterest;
    const monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    reset({
      vehiclePrice: vehiclePrice.toLocaleString(),
      downPercent: downPercent.toString(),
      down: down.toLocaleString(),
      financeAmount: financeAmount.toLocaleString(),
      installmentTotalMonth: installmentTotalMonth.toString(),
      interestPercent: interestPercent.toString(),
      totalInterest: totalInterest.toLocaleString(),
      totalPaidAmount: totalPaidAmount.toLocaleString(),
      monthlyInstallment: monthlyInstallment.toLocaleString(),
    });
  }, []);

  useEffect(() => {
    if (inputNameKeyStroke !== "vehiclePrice") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? vehiclePrice
     * ! fixed: vehiclePrice, installmentTotalMonth, interestPercent
     * * อยากรู้:
     */

    down = (vehiclePrice * downPercent) / 100;
    financeAmount = vehiclePrice - down;
    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    if (!isNaN(vehiclePrice)) {
      setValue("down", down.toLocaleString());
      setValue("financeAmount", financeAmount.toLocaleString());
      setValue("totalInterest", totalInterest.toLocaleString());
      setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
      setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
    }
  }, [watch("vehiclePrice")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "downPercent") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? downPercent
     * ! fixed: vehiclePrice, installmentTotalMonth, interestPercent
     * * อยากรู้:
     * * down
     * * financeAmount
     * * totalInterest
     * * totalPaidAmount
     * * monthlyInstallment
     */
    // (ใช้ไป% * ทั้งหมด) / 100
    down = (downPercent * vehiclePrice) / 100;

    financeAmount = vehiclePrice - down;
    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("down", down.toLocaleString());
    setValue("financeAmount", financeAmount.toLocaleString());
    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("downPercent")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "down") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? down
     * ! fixed: vehiclePrice, installmentTotalMonth, interestPercent
     * * อยากรู้:
     * * downPercent
     * * financeAmount
     * * totalInterest
     * * totalPaidAmount
     * * monthlyInstallment
     */

    // (ใช้ไป * 100) / ทั้งหมด
    downPercent = (down * 100) / vehiclePrice;

    financeAmount = vehiclePrice - down;
    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("downPercent", downPercent.toString());
    setValue("financeAmount", financeAmount.toLocaleString());
    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("down")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "financeAmount") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? financeAmount ,, มาจาก vehiclePrice - down
     * ! fixed: vehiclePrice, installmentTotalMonth, interestPercent
     * * อยากรู้:
     * * downPercent
     * * down
     * * totalInterest
     * * totalPaidAmount
     * * monthlyInstallment
     */

    down = vehiclePrice - financeAmount;
    // (ใช้ไป * 100) / ทั้งหมด
    downPercent = (down * 100) / vehiclePrice;

    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("down", down.toLocaleString());
    setValue("downPercent", downPercent.toString());
    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("financeAmount")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "installmentTotalMonth") {
      return;
    }

    let {
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? installmentTotalMonth
     * ! fixed: vehiclePrice, interestPercent, downPercent, down
     * * อยากรู้:
     * * totalInterest
     * * totalPaidAmount
     * * monthlyInstallment
     */

    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("installmentTotalMonth")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "interestPercent") {
      return;
    }

    let {
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? interestPercent
     * ! fixed: vehiclePrice, downPercent, down, financeAmount, installmentTotalMonth
     * * อยากรู้:
     * * totalInterest
     * * totalPaidAmount
     * * monthlyInstallment
     */

    const interestPerYear = (financeAmount * interestPercent) / 100;
    totalInterest = interestPerYear * monthToYear(installmentTotalMonth);
    totalPaidAmount = +financeAmount + totalInterest;
    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("interestPercent")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "totalPaidAmount") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? totalPaidAmount
     * ! fixed: vehiclePrice, financeAmount, installmentTotalMonth, interestPercent
     * * อยากรู้:
     * * down
     * * downPercent
     * * totalInterest
     * * financeAmount
     * * monthlyInstallment
     */

    const totalInterestPercent =
      interestPercent * monthToYear(installmentTotalMonth);

    totalInterest = (totalPaidAmount * totalInterestPercent) / 100;

    financeAmount = totalPaidAmount - totalInterest;
    down = vehiclePrice - financeAmount;
    // (ใช้ไป * 100) / ทั้งหมด
    downPercent = (down * 100) / vehiclePrice;

    monthlyInstallment = totalPaidAmount / installmentTotalMonth;

    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("financeAmount", financeAmount.toLocaleString());
    setValue("down", down.toLocaleString());
    setValue("downPercent", downPercent.toString());
    setValue("monthlyInstallment", monthlyInstallment.toLocaleString());
  }, [watch("totalPaidAmount")]);

  useEffect(() => {
    if (inputNameKeyStroke !== "monthlyInstallment") {
      return;
    }

    let {
      vehiclePrice,
      downPercent,
      down,
      financeAmount,
      installmentTotalMonth,
      interestPercent,
      totalInterest,
      totalPaidAmount,
      monthlyInstallment,
    } = getData(getValues);

    /**
     * ? monthlyInstallment,, ต้องดาวน์เท่าไหร่
     * ! fixed: vehiclePrice, installmentTotalMonth, interestPercent, monthlyInstallment
     * * อยากรู้:
     * * down
     * * downPercent
     * * totalInterest
     * * financeAmount
     * * totalPaidAmount
     */

    totalPaidAmount = monthlyInstallment * installmentTotalMonth;
    const totalInterestPercent =
      interestPercent * monthToYear(installmentTotalMonth);

    totalInterest = (totalPaidAmount * totalInterestPercent) / 100;
    financeAmount = totalPaidAmount - totalInterest;
    down = vehiclePrice - financeAmount;
    // (ใช้ไป * 100) / ทั้งหมด
    downPercent = (down * 100) / vehiclePrice;

    setValue("totalPaidAmount", totalPaidAmount.toLocaleString());
    setValue("totalInterest", totalInterest.toLocaleString());
    setValue("financeAmount", financeAmount.toLocaleString());
    setValue("down", down.toLocaleString());
    setValue("downPercent", downPercent.toString());
  }, [watch("monthlyInstallment")]);

  return (
    <section>
      <Container maxWidth="lg">
        <Paper elevation={0} variant="outlined" style={{ padding: rhythm(1) }}>
          <form autoComplete="off">
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    borderBottom: ".5px solid",
                    marginBottom: rhythm(1),
                  }}
                >
                  ข้อมูล
                </p>
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("vehiclePrice");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={register("vehiclePrice")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ endAdornment: "บาท" }}
                  label="ราคารถ (บาท)"
                  error={
                    !!errors?.vehiclePrice && !!errors?.vehiclePrice?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.vehiclePrice && errors?.vehiclePrice?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <TextField
                  onKeyDown={AllowInputDecimal}
                  onKeyUp={(e) => {
                    const value = (e.target as HTMLInputElement).value;
                    if (!value) {
                      (e.target as HTMLInputElement).value = Number(
                        value
                      ).toLocaleString();
                    }
                    if (
                      value.startsWith("0") &&
                      e.key !== "." &&
                      !/\./g.test(value)
                    ) {
                      (e.target as HTMLInputElement).value = Number(
                        value
                      ).toLocaleString();
                    }

                    if (Number(value) > 100) {
                      (e.target as HTMLInputElement).value = Number(
                        "100"
                      ).toLocaleString();
                    }
                    setInputNameKeyStroke("downPercent");
                  }}
                  inputProps={register("downPercent")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="จำนวนเงินดาวน์ (%)"
                  InputProps={{ endAdornment: "%" }}
                  error={
                    !!errors?.downPercent && !!errors?.downPercent?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.downPercent && errors?.downPercent?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("down");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={register("down")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="เงินดาวน์"
                  InputProps={{ endAdornment: "บาท" }}
                  error={!!errors?.down && !!errors?.down?.message}
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.down && errors?.down?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <div style={{ width: "100%" }}>
                  <InstallmentTotalMonthTextField
                    select
                    name="installmentTotalMonth"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="จำนวนเดือนที่ผ่อน"
                    error={
                      !!errors?.installmentTotalMonth &&
                      !!errors?.installmentTotalMonth?.message
                    }
                    helperText={
                      <span style={{ color: theme?.palette?.error?.main }}>
                        {errors?.installmentTotalMonth &&
                          errors?.installmentTotalMonth?.message}
                      </span>
                    }
                    fullWidth
                    style={{ marginBottom: rhythm(1) }}
                    control={control}
                  >
                    <MenuItem value="12">12 เดือน (1 ปี)</MenuItem>
                    <MenuItem value="24">24 เดือน (2 ปี)</MenuItem>
                    <MenuItem value="36">36 เดือน (3 ปี)</MenuItem>
                    <MenuItem value="48">48 เดือน (4 ปี)</MenuItem>
                    <MenuItem value="60">60 เดือน (5 ปี)</MenuItem>
                    <MenuItem value="72">72 เดือน (6 ปี)</MenuItem>
                    <MenuItem value="84">84 เดือน (7 ปี)</MenuItem>
                    <MenuItem value="96">96 เดือน (8 ปี)</MenuItem>
                    <MenuItem value="108">108 เดือน (9 ปี)</MenuItem>
                  </InstallmentTotalMonthTextField>
                </div>
                <TextField
                  onKeyDown={AllowInputDecimal}
                  onKeyUp={(e) => {
                    const value = (e.target as HTMLInputElement).value;
                    if (!value) {
                      (e.target as HTMLInputElement).value = Number(
                        value
                      ).toLocaleString();
                    }
                    if (
                      value.startsWith("0") &&
                      e.key !== "." &&
                      !/\./g.test(value)
                    ) {
                      (e.target as HTMLInputElement).value = Number(
                        value
                      ).toLocaleString();
                    }

                    if (Number(value) > 100) {
                      (e.target as HTMLInputElement).value = Number(
                        "100"
                      ).toLocaleString();
                    }

                    setInputNameKeyStroke("interestPercent");
                  }}
                  inputProps={register("interestPercent")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="ดอกเบี้ย (%) ต่อปี"
                  InputProps={{ endAdornment: "%" }}
                  error={
                    !!errors?.interestPercent &&
                    !!errors?.interestPercent?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.interestPercent &&
                        errors?.interestPercent?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    borderBottom: ".5px solid",
                    marginBottom: rhythm(1),
                  }}
                >
                  ผลลัพธ์
                </p>
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("financeAmount");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={register("financeAmount")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="ยอดจัดไฟแนนซ์"
                  InputProps={{ endAdornment: "บาท" }}
                  error={
                    !!errors?.financeAmount && !!errors?.financeAmount?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.financeAmount && errors?.financeAmount?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("totalInterest");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={{ ...register("totalInterest"), readOnly: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="ดอกเบี้ยรวมทั้งหมด"
                  InputProps={{ endAdornment: "บาท" }}
                  error={
                    !!errors?.totalInterest && !!errors?.totalInterest?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.totalInterest && errors?.totalInterest?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("totalPaidAmount");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={register("totalPaidAmount")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="ค่างวดรวมทั้งหมด"
                  InputProps={{ endAdornment: "บาท" }}
                  error={
                    !!errors?.totalPaidAmount &&
                    !!errors?.totalPaidAmount?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.totalPaidAmount &&
                        errors?.totalPaidAmount?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <TextField
                  onKeyDown={AllowInputNumber}
                  onKeyUp={(e) => {
                    setInputNameKeyStroke("monthlyInstallment");
                    const value = (e.target as HTMLInputElement).value.replace(
                      /\,/g,
                      ""
                    );

                    (e.target as HTMLInputElement).value = Number(
                      value
                    ).toLocaleString();
                  }}
                  inputProps={register("monthlyInstallment")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="ค่างวดต่อเดือน"
                  InputProps={{ endAdornment: "บาท" }}
                  error={
                    !!errors?.monthlyInstallment &&
                    !!errors?.monthlyInstallment?.message
                  }
                  helperText={
                    <span style={{ color: theme?.palette?.error?.main }}>
                      {errors?.monthlyInstallment &&
                        errors?.monthlyInstallment?.message}
                    </span>
                  }
                  fullWidth
                  style={{ marginBottom: rhythm(1) }}
                />
                <p style={{ marginTop: rhythm(1), textAlign: "center" }}>
                  *เพื่อประกอบการตัดสินใจ เท่าน้้น*
                </p>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </section>
  );
}

export default FinanceVehicleCalculate;
